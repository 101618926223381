import * as React from 'react'

import {
	Button,
	Flex,
	Text,
	Heading,
	useToast,
	Container
} from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import RfidCard from '../../../../icons/rfid-card.svg'
import { object, string } from 'yup'
import { setRFID } from '../../../../helpers/database'
import { navigate } from 'gatsby'
import Input from '../../../../components/input'
import QRScanner from '../../../../components/QRScanner'
import { errorToastOptions } from '../../../../helpers'
import { useLocation } from '@reach/router'
import BasePage from '../../../../components/basePage'
import { assignRfidToMember, getBooking } from '../../../../services/booking'
import { Textfit } from 'react-textfit'
import RegModal from '../../../../components/modal'
import Spinner from '../../../../components/spinner'
import Layout from '../../../../layout'

const LinkRFIDPage = ({ bookingId, memberId }) => {
	const { state } = useLocation()
	const rfid = state?.rfid

	const [showErrorModal, setShowErrorModal] = useState(false)

	const { data: groupData } = useQuery({
		queryKey: ['booking', bookingId],
		queryFn: () => getBooking(bookingId),
		retry: false
	})

	const resolver = yupResolver(
		object()
			.shape({
				code: string().trim().required().min(4)
			})
			.required()
	)
	const {
		watch,
		register,
		handleSubmit,
		reset,
		getValues,
		setValue,
		formState: { errors }
	} = useForm({
		resolver,
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		shouldUseNativeValidation: false
	})
	const watchCode = watch('code')
	const [loading, setLoading] = useState(false)

	const toast = useToast()

	const isValid = !!watchCode

	const onValidateCode = async () => {
		const data = getValues()
		const groupId = groupData?.group?.id
		if (!data?.code || !groupId) return

		onSubmit(data, groupId)
	}

	const onNewScanResult = (decodedText) => {
		// handle decoded results here

		let code
		if (decodedText.toString().includes('http')) {
			code = decodedText.split('cardid=')[1]
		} else {
			code = decodedText
		}

		const cleanedId = code?.replace(/<|>/g, '')
		const splitId = cleanedId.split('')
		const segmentedId = []
		for (let i = 0; i < splitId.length; i += 2) {
			segmentedId.unshift(`${splitId[i]}${splitId[i + 1]}`)
		}
		const reversedId = segmentedId.join('')

		setValue('code', reversedId)
		onValidateCode()
	}

	const onSubmit = async (data, groupId) => {
		setRFID(data)
		setLoading(true)

		try {
			await assignRfidToMember(bookingId, memberId, data.code, groupId)
			setLoading(false)
			await navigate(`/manage-booking/${bookingId}/ready-to-play`)
		} catch (err) {
			setLoading(false)
			console.log({ err })
			setShowErrorModal(true)
			// toast({
			// 	description:
			// 		'Oops! something went wrong assigning this rfid, please try again',
			// 	...errorToastOptions
			// })
		}
	}

	const handleLinkNewCard = () => {
		setShowErrorModal(false)
		setValue('code', '')
	}

	if (!!bookingId && bookingId !== 'null') {
		setRFID({ bookingId })
	}

	if (loading) {
		return (
			<Layout>
				<Flex
					alignItems={'center'}
					justifyContent={'center'}
					height="100vh"
				>
					<Spinner />
				</Flex>
			</Layout>
		)
	}

	return (
		<form onSubmit={handleSubmit(onValidateCode)}>
			<BasePage
				theme="dark"
				access={1}
				fullWidthActionBtns
				noHeader={false}
				hideSidebar={true}
				primaryBtn={
					<Button
						variant="primary"
						isLoading={loading}
						disabled={!isValid}
						type="submit"
					>
						Link
					</Button>
				}
				secondaryBtn={
					<Button
						width="full"
						mt="5"
						variant="link"
						onClick={() => navigate(`/manage-booking/${bookingId}`)}
					>
						Cancel
					</Button>
				}
			>
				{rfid ? (
					<Flex justifyContent="end">
						<Flex
							background="darkGray"
							borderRadius="full"
							alignItems="center"
							justifyContent="center"
							px={2}
							py={1}
							gap={1}
						>
							<StaticImage
								src="../../../../images/icon-ok.png"
								style={{
									height: 22,
									width: 22,
									objectFit: 'contain'
								}}
								alt="ok"
							/>
							<Text
								textColor="white"
								textTransform="uppercase"
								fontWeight={700}
							>
								{rfid}
							</Text>
						</Flex>
					</Flex>
				) : (
					<Flex
						background="darkGray"
						p={5}
						flexDir="column"
						alignItems="center"
						textColor="white"
					>
						<Text fontWeight={700} fontSize={18}>
							Congratulations, you've joined
						</Text>
						<Flex
							position="relative"
							h="fit-content"
							w="full"
							alignItems="center"
							justifyContent="center"
						>
							<StaticImage
								position="absolute"
								style={{
									position: 'absolute',
									top: 0,
									left: 0,
									bottom: 0,
									right: 0
								}}
								alt="Banner"
								src="../../../../images/board-grey.png"
							/>
							<Textfit
								mode="single"
								max={36}
								forceSingleModeWidth={true}
								style={{
									zIndex: 1,
									color: 'black',
									fontWeight: 800,
									padding: '12px 20px',
									width: '100%',
									textAlign: 'center'
								}}
							>
								{groupData?.group?.name}
							</Textfit>
						</Flex>
					</Flex>
				)}
				<Flex justifyContent="center" alignItems="center" gap={2}>
					<RfidCard width="80px" />
					<Heading as="h1" color={'white'} textAlign="left">
						{rfid ? (
							<>
								Link New
								<br />
								card
							</>
						) : (
							<>
								Now link
								<br />
								your card
							</>
						)}
					</Heading>
				</Flex>
				<Container maxWidth={'80%'} pt={'70%'} position={'relative'}>
					<Container
						position={'absolute'}
						top={0}
						left={0}
						height={'100%'}
						width={'100%'}
					>
						<QRScanner qrCodeSuccessCallback={onNewScanResult} />
					</Container>
				</Container>
				<Input
					name="code"
					type="code"
					variant="filled"
					placeholder="Games Card Code"
					mt="40px"
					register={register}
					errors={errors}
				/>
				<Text variant="m" mt="10px" color="white" textAlign="center">
					Tip: Find your RFID code below <br />
					the QR code on your card.
				</Text>
				<RegModal
					opened={showErrorModal}
					onClose={() => setShowErrorModal(false)}
					title="Oops!"
					description={
						<Text variant="m" px="10px" textAlign="center" mb={4}>
							This card is already linked to another player
						</Text>
					}
					isPink={true}
					primaryBtn={
						<Button variant="primary" onClick={handleLinkNewCard}>
							Link New Card
						</Button>
					}
					secondaryBtn={
						<Button
							variant="cancel"
							onClick={() =>
								navigate(`/manage-booking/${bookingId}`)
							}
						>
							Back to booking
						</Button>
					}
				></RegModal>
			</BasePage>
		</form>
	)
}

export default LinkRFIDPage
